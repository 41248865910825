<template>
<div>
  <h5 v-if="title" class="mb-2 wsDARKER">{{  titleRaw || $t(title)  }}</h5>
  <v-sheet style="border-radius: 8px; box-shadow: 0 2px 10px 0 rgba(154, 178, 196, 0.1);" class="overflow-hidden" >


    <v-hover v-for="(item , i) in itemsFiltered" :key="i" #default="{hover}">
      <ws-link
          :href="item.url"
          new-window
          :disabled="!item.url"
      >
        <v-sheet
            @click="handleAction(item , i)"
            :color="hover ? wsLIGHTCARD : (item.display_more ? wsLIGHTACCENT : null)"
            :style="(displayMore && ! item.display_more) || (!displayMore && i < (items.length - 1)) ? `border-bottom: 1px solid ${wsBACKGROUND}` : null"
            class="d-flex align-center justify-space-between py-3 px-4 pointer"
            style="box-sizing: border-box;"
            min-height="48"
            v-ripple
        >
          <div class="d-flex align-center pr-1">
            <v-icon
                v-if="item.icon"
                :color="item.icon_color || wsATTENTION"
                class="mr-2"
                :small="item.small_icon"
            >
              {{ item.icon || "mdi-cog" }}
            </v-icon>

            <div>
              <h5 :style="`font-size: ${item.bold ? 13 : 12}px`"
                  class="wsDARKER "
                  :class="[{'font-weight-regular' : !item.bold}]"
              >
                {{ item.text }}
              </h5>
              <h5 v-if="item.subtitle" class="wsDARKLIGHT font-weight-regular" style="font-size: 12px">
                {{ item.subtitle }}
              </h5>
            </div>

          </div>

          <div class="d-flex align-center">

            <v-sheet v-if="item.counter"
                     :color="wsDARKLIGHT"
                     class="d-flex align-center justify-center "
                     dark
                     style="border-radius: 100px; padding: 2px 8px"
            >
              <h6 style="font-size: 12px">{{ item.counter }}</h6>
            </v-sheet>

            <v-hover v-if="!item.hide_chevron" #default="{hover}">
              <v-sheet :color="hover ? wsBACKGROUND : 'transparent' " class="d-flex pa-1 align-center justify-center" style="border-radius: 50%">
                <v-icon :color="wsACCENT">{{ item.icon_right || "mdi-chevron-right" }}</v-icon>
              </v-sheet>
            </v-hover>
          </div>

        </v-sheet>

        <v-expand-transition>
          <div v-if="expandObject[i]">
            <slot name="expand" :item="item">
              <h5>{{ item.expand_content }}</h5>
            </slot>
          </div>
        </v-expand-transition>


      </ws-link>

    </v-hover>







  </v-sheet>

</div>
</template>

<script>
export default {
  name: "supportNavigation",
  props : {
    items : {
      type : Array,
      default() { return [] }
    },
    title : {
      type : String
    },
    titleRaw : {
      type : String
    },
    text : {
      type : String
    },
    icon : {
      type : String
    },
    bold : {
      type : Boolean
    },
    iconColor : {
      type : String
    },
    displayMore : {
      type : Boolean
    },
    displayMoreText : {
      type : String
    }
  },
  data() {
    return {
      expandObject : {}
    }
  },
  computed : {
    itemsFiltered() {
      if ( this.text ) {
        return [{
          text : this.text ,
          icon : this.icon || null ,
          icon_color : this.iconColor || null,
          bold : this.bold || false
        }]
      }

      if ( this.displayMore ) {
        return [...this.items , {
          text : this.displayMoreText || this.$t('All'),
          display_more : true
        } ]
      }

      return this.items
    },
    itemsLength() {
      return this.itemsFiltered.length
    }
  },
  watch : {
    itemsLength() {
      this.createExpandObject()
    }
  },
  methods: {
    handleAction(item,index) {
      if ( item.url ) {
        return
      }
      if ( item.expandable ) {
        this.expandObject[index] = ! this.expandObject[index]
        return
      }
      this.$emit('input' , item.value);
      this.$emit('select-item' , item)
      if ( item.action ) {
        item.action()
      }
    },
    createExpandObject() {
      this.expandObject = {}
      this.itemsFiltered.forEach((_,i)=>{
        this.expandObject[i] = false
        this.expandObject = this.COPY(this.expandObject)
      })
    }
  },
  mounted() {
    this.createExpandObject()
  }

}
</script>

<style scoped>

</style>